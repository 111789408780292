import Fuse from 'fuse.js'

export const setupFuse = (templates) => {
  const fuseOptions = {
    includeScore: true, // If you still need it
    keys: [
      {
        name: 'name',
        weight: 0.7
      },
      {
        name: 'description',
        weight: 0.2
      }
    ]
  }
  return new Fuse(templates, fuseOptions)
}
