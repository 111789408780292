<template>
  <div
    class="inputs"
    v-on="null"
  >
    <v-slider
      v-if="showSlider"
      :disabled="disabled"
      :value="value"
      :prepend-icon="icon"
      :min="min"
      :max="max"
      :step="step"
      thumb-label
      dense
      @change="handleValueChange"
    />
    <v-text-field
      :disabled="disabled"
      :value="value"
      class="dimension-text-field"
      :class="{ 'highlight': missingValue }"
      type="number"
      hide-details
      :suffix="suffix"
      :prepend-icon="!showSlider ? icon : null"
      :style="showSlider ? 'max-width: 100px; max-width: max(20%, 100px);' : null"
      @change="handleValueChange"
    />
  </div>
</template>

<script>
export default {
  name: 'TextFieldAndSlider',
  props: {
    initialValue: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    },
    icon: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false
    },
    showSlider: {
      type: Boolean,
      required: true
    },
    enableZeroAlerts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: 0
    }
  },
  computed: {
    missingValue () {
      return this.enableZeroAlerts && (this.value === 0 || this.value === '0')
    }
  },
  watch: {
    initialValue (e) {
      this.value = e
    }
  },
  created () {
    this.value = this.initialValue
  },
  methods: {
    handleValueChange (value) {
      this.value = value
      if (!Number.isNaN(Number(value))) {
        this.$emit('change', Number(value))
      }
    }
  }
}
</script>

<style lang="scss">
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--box-shadow-color);
  }
  90% {
    box-shadow: 0 0 3px 3px rgba(0,0,0,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
  }
}

.dimension-text-field {
  position: relative;
  &.editing > .v-input__control {
    background-color: rgba(0, 163, 95, 0.09);
    input {
      cursor: pointer;
    }
  }
  &.highlight .v-text-field__slot {
    position: relative;
    &::after {
      --box-shadow-color: #70d270;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 30%;
      bottom: 0;
      // box-shadow: 0 0 7px #70d270;
      animation: pulse 1.6s infinite;
    }
  }
  .v-input__prepend-outer .v-icon {
    color: var(--v-primary-base) !important;
    &.function-icon {
      color: #666 !important;
      font-size: 20px;
      position: relative;
      top: 2px;
      right: 5px;
    }
  }
  .v-tooltip__content {
    color: var(--v-primary);
    top: calc(100% + 15px) !important;
    z-index: 10 !important;
    pointer-events: auto;
    opacity: 1 !important;
    box-shadow: 1px 3px 8px rgba(0,0,0,0.15);
    &::after {
      position: absolute;
      content: "";
      bottom: 100%;
      left: 50px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid #fff;
    }
    span {
      display: block;
      margin: 10px 0 7px 0;
    }
    .v-input--radio-group {
      margin-top: 0;
      .v-label {
        margin-bottom: 10px;
      }
      .v-input--radio-group__input {
        flex-wrap: nowrap;
      }
    }
  }
}
.inputs {
  display: flex;
  align-items: center;
  .v-input__slider {
    margin-top: 10px;
    margin-right: 15px;
    .v-slider__track-container {
      height: 2px;
      transform: scaleY(3.5)!important;
    }
    .v-slider__track-background {
      background-color: var(--v-primary-base);
      opacity: 0.2;
    }
    .v-slider__thumb {
      height: 20px;
      width: 20px;
      transform: translateY(calc(1px - 50%));
      cursor: pointer;
      &::before {
        display: none;
      }
    }
    .v-slider__thumb-label {
      transform: translateY(-20%) translateY(-14px) translateX(-50%) translateX(5px) rotate(45deg) !important;
    }
  }
  .v-text-field {
    padding-top: 0;
    .v-input__prepend-outer {
      margin: 7px 5px 0 0;
    }
  }
  .mock-select input {
    text-align: right;
    text-align: end;
  }
  .v-select, .mock-select {
    max-width: 100px;
    .v-select__selections {
      width: 90px;
      max-width: 100px;
    }
    .v-select__slot {
      justify-content: flex-end;
    }
    .v-input__append-inner {
      margin: 0;
    }
  }
  .v-select__selection {
    width: 100%;
    justify-content: flex-end;
    text-align: right;
  }
  .v-messages {
    min-height: 0;
  }
}
</style>
