
































import Vue from 'vue'

export default Vue.extend({
  name: 'SimpleDialog',
  data () {
    return ({
      dialog: false
    })
  }
})
