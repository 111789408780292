<template>
  <div class="highcharts-donut">
    <highcharts
      ref="chart"
      :style="height && `height: ${height} !important;`"
      class="hc"
      :options="mergedChartOptions"
    />
  </div>
</template>

<script>

export default {
  name: 'ShadedChart',
  props: {
    seriesData: {
      type: Array,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        credits: {
          enabled: false
        },
        chart: {
          spacing: [0, 50, 0, 50]
        },
        title: {
          text: undefined,
          align: 'center'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          plotOptions: {
            pie: {
              center: ['50%', '50%']
            }
          },
          series: {
            borderRadius: 0,
            allowPointSelect: false,
            borderWidth: 0,
            colorByPoint: true
          }
        },
        colors: ['#525a61', '#dbdfe3', '#8a97a2'],
        series: [
          {
            startAngle: 45,
            size: '100%',
            innerSize: '75%',
            type: 'pie',
            name: 'inner',
            dataLabels: [
              {
                connectorColor: '#8a97a2',
                enabled: true,
                overflow: 'allow',
                padding: 4,
                format: '{point.name}<br>{point.percentage:.0f}%',
                crop: false,
                distance: '30%',
                style: {
                  color: '#8a97a2',
                  fontWeight: 'bold',
                  textOutline: 'none',
                  fontSize: '12px'
                }
              }
            ],
            data: [['Trees', 1], ['Unshaded', 2], ['Structures', 3]]
          }
        ]
      })
    }
  },
  data () {
    return {
      mergedChartOptions: this.mergeChartOptions(this.seriesData)
    }
  },
  watch: {
    seriesData (seriesData) {
      this.chart = this.$refs['chart'].chart
      this.chart.update(this.mergeChartOptions(seriesData), true)
    }
  },
  methods: {
    mergeChartOptions (data) {
      const seriesData = this.createDataSeries(data)

      const series = [
        this.seriesData ? {
          ...this.chartOptions.series[0],
          data: seriesData
        } : {}
      ]
      const chartOptions = {
        ...this.chartOptions,
        series
      }
      return chartOptions
    },
    createDataSeries (data) {
      return data?.map((val, i) => {
        return [val[0], val[1]]
      }) || []
    }
  }
}

</script>
<style scoped>
  .hc {
    height: 100%;
    width: 100%;
    overflow: visible !important;
  }
  ::v-deep .highcharts-container {
    overflow: visible !important;
  }
  ::v-deep .highcharts-root {
    overflow: visible !important;
  }
  .highcharts-donut {
    height: 100%;
    width: 100%;
  }
</style>
