<template>
  <div class="highcharts-donut">
    <highcharts
      ref="chart"
      :style="height && `height: ${height} !important;`"
      class="hc"
      :options="mergedChartOptions"
    />
  </div>
</template>

<script>

import { merge } from 'lodash-es'
import numeral from 'numeral'

export default {
  name: 'EcosystemPreservation',
  props: {
    seriesData: {
      type: Array,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        credits: {
          enabled: false
        },
        chart: {
          spacing: [0, 50, 0, 50]
        },
        title: {
          text: undefined,
          align: 'center'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            center: ['50%', '50%']
          },
          series: {
            borderRadius: 0,
            allowPointSelect: false,
            borderWidth: 0,
            colorByPoint: true
          }
        },
        colors: ['#00A25F', '#8a97a2'],
        series: [
          {
            startAngle: 75,
            size: '100%',
            innerSize: '75%',
            type: 'pie',
            name: 'inner',
            dataLabels: [
              {
                enabled: true,
                overflow: 'allow',
                padding: 0,
                allowOverlap: true,
                formatter: function () {
                  const point = this.point
                  const value = numeral(point.y).format('0,0')
                  return `<span style="color: ${point.color}">${value}%</span>`
                },
                crop: false,
                distance: '-63%',
                color: null,
                useHTML: true,
                style: {
                  color: null,
                  fontWeight: 'bold',
                  textOutline: 'none',
                  fontSize: '24px'
                }
              },
              {
                enabled: true,
                overflow: 'allow',
                padding: 0,
                allowOverlap: true,
                format: '{point.name}',
                crop: false,
                distance: '18%',
                color: null,
                style: {
                  color: '#8a97a2',
                  fontWeight: 'bold',
                  textOutline: 'none',
                  fontSize: '12px'
                }
              }
            ],
            data: []
          }
        ]
      })
    }
  },
  data () {
    return {
      mergedChartOptions: this.mergeChartOptions(this.seriesData)
    }
  },
  watch: {
    seriesData (seriesData) {
      this.chart = this.$refs['chart'].chart
      this.chart.update(this.mergeChartOptions(seriesData), true)
    }
  },
  methods: {
    mergeChartOptions (data) {
      const seriesData = merge({}, this.chartOptions)
      seriesData.series[0].data = data
      return seriesData
    }
  }
}

</script>
<style scoped>
.hc {
  height: 100%;
  width: 100%;
  overflow: visible !important;
}
::v-deep .highcharts-container {
  overflow: visible !important;
}
::v-deep .highcharts-root {
  overflow: visible !important;
}
.highcharts-donut {
  height: 100%;
  width: 100%;
}
</style>
