<template>
  <div class="highcharts-donut">
    <highcharts
      ref="chart"
      :style="height && `height: ${height} !important;`"
      class="hc"
      :options="mergedChartOptions"
    />
  </div>
</template>

<script>

import numeral from 'numeral'
import { merge } from 'lodash-es'

export default {
  name: 'Impact',
  props: {
    seriesData: {
      type: Array,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    impactValue: {
      type: Number,
      required: true
    },
    impactText: {
      type: Function,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({
        credits: {
          enabled: false
        },
        chart: {
          spacing: [0, 50, 0, 50]
        },
        title: {
          text: undefined,
          align: 'center'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            center: ['50%', '50%']
          },
          series: {
            allowPointSelect: false,
            borderRadius: 0,
            borderWidth: 0,

            colorByPoint: true,
            dataLabels: [{
              enabled: true,
              overflow: 'allow',
              padding: 4,
              formatter: function () {
                const formattedNumber = numeral(this.point.impactValue).format('0,0')
                return `<div class="impact-value">${formattedNumber}%</div><div class="impact-text">${this.point.impactText}</div>`
              },
              useHTML: true,
              crop: false,
              distance: '-100%',
              style: {
                color: '#8a97a2',
                fontWeight: 'bold',
                textOutline: 'none',
                fontSize: '13px',
                textAlign: 'center',
                lineHeight: '25px'
              }
            }],
            innerSize: '75%',
            name: 'inner',
            size: '100%',
            startAngle: 45
          }
        },
        series: [
          {
            type: 'pie'
          }
        ]
      })
    }
  },
  data () {
    return {
      mergedChartOptions: this.mergeChartOptions(this.seriesData)
    }
  },
  watch: {
    seriesData (seriesData) {
      this.chart = this.$refs['chart'].chart
      this.chart.update(this.mergeChartOptions(seriesData), true)
    }
  },
  methods: {
    mergeChartOptions (data) {
      const seriesData = merge({}, this.chartOptions)
      seriesData.series[0].data = [{
        name: 'Value',
        y: 1,
        impactValue: this.impactValue,
        impactText: this.impactText(this.impactValue),
        color: this.impactValue >= 0 ? '#00A25F' : '#8a97a2'
      }]
      return seriesData
    }
  }
}

</script>
<style>
.hc {
  height: 100%;
  width: 100%;
  overflow: visible !important;
}
::v-deep .highcharts-container {
  overflow: visible !important;
}
::v-deep .highcharts-root {
  overflow: visible !important;
}
.impact-value {
  font-size: 20px;
}
.highcharts-donut {
  height: 100%;
  width: 100%;
}
</style>
