<template>
  <div>
    <v-flex class="d-flex justify-center align-center">
      <v-img
        :src="imageSrc"
        :alt="imageAlt"
        contain
        class="mb-4"
      />
    </v-flex>
    <v-flex class="d-flex justify-center align-center">
      <v-radio
        class="ma-0 pa-0 fix-margin"
        hide-details
        :value="radioValue"
      />
    </v-flex>
  </div>
</template>

<script>
export default {
  name: 'RadioImage',
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      default: 'image'
    },
    radioValue: {
      type: [String, Number, Boolean],
      required: true
    }
  }
}
</script>

<style scoped>
.fix-margin >>> .v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>
