<template>
  <div
    class="highcharts-donut"
    :style="height && `height: ${height} !important;`"
  >
    <highcharts
      ref="chart"
      class="hc"
      :options="mergedChartOptions"
    />
  </div>
</template>

<script>
import noDataInit from 'highcharts/modules/no-data-to-display'
import Highcharts from 'highcharts'
import colors from '../../schema/colors'

noDataInit(Highcharts)

export default {
  name: 'WaterReductionFromBaseline',
  props: {
    reduction: {
      type: Number,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: null
    },
    chartOptions: {
      type: Object,
      default: () => ({
        credits: {
          enabled: false
        },
        chart: {
          spacing: [0, 50, 0, 50]
        },
        lang: {
          noData: 'Baseline has not been defined'
        },
        title: {
          text: undefined,
          align: 'center'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          plotOptions: {
            pie: {
              center: ['50%', '50%']
            }
          },
          series: {
            borderRadius: 0,
            allowPointSelect: false,
            borderWidth: 0,
            colorByPoint: true
          }
        },
        colors: [colors.metal, '#dbdfe3'],
        series: [
          {
            startAngle: 0,
            size: '100%',
            innerSize: '75%',
            type: 'pie',
            name: 'inner',
            dataLabels: [
              {
                connectorColor: '#8a97a2',
                enabled: true,
                overflow: 'allow',
                padding: 0,
                format: '<br>{point.percentage:.0f}%',
                crop: false,
                distance: '-100%',
                style: {
                  color: '#8a97a2',
                  fontWeight: 'bold',
                  textOutline: 'none',
                  fontSize: '30px'
                }
              }
            ],
            data: [['ReductionFromBaseline', 100], ['Remaining', 0]]
          }
        ]
      })
    }
  },
  data () {
    return {
      mergedChartOptions: this.mergeChartOptions({ reduction: this.reduction })
    }
  },
  watch: {
    baseline (reduction) {
      this.chart = this.$refs['chart'].chart
      this.chart.update(this.mergeChartOptions({ reduction }), true)
    },
    reduction (reduction) {
      this.chart = this.$refs['chart'].chart
      this.chart.update(this.mergeChartOptions({ reduction }), true)
    }
  },
  methods: {
    mergeChartOptions ({ reduction }) {
      const remaining = (100 - (reduction || 0))
      const seriesData = [
        {
          name: 'Reduction',
          y: reduction,
          color: reduction > 30 ? colors.cpdblue : colors.metal,
          dataLabels: {
            color: reduction > 30 ? colors.cpdblue : colors.metal
          }
        },
        {
          name: 'Remaining',
          y: remaining,
          dataLabels: {
            enabled: false
          }
        }
      ]
      const series = [
        seriesData ? {
          ...this.chartOptions.series[0],
          data: seriesData
        } : {}
      ]
      const chartOptions = {
        ...this.chartOptions,
        series
      }
      return chartOptions
    }
  }
}

</script>
<style scoped>
.hc {
  height: 100%;
  width: 100%;
  overflow: visible !important;
}
::v-deep .highcharts-container {
  overflow: visible !important;
}
::v-deep .highcharts-root {
  overflow: visible !important;
}
.highcharts-donut {
  height: 100%;
  width: 100%;
}
</style>
