<template>
  <v-dialog
    v-model="showDialog"
    width="440"
    @input="$emit('toggle')"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            small
            class="m-3"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon
              style="font-size: 17px"
              color="onyx"
            >
              mdi-note
            </v-icon>
          </v-btn>
        </template>
        View or add notes
      </v-tooltip>
    </template>
    <v-card class="d-block">
      <v-card-text>
        <v-textarea
          v-model="notes"
          class="pt-6 pb-3 px-3"
          filled
          label="Notes"
          auto-grow
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="saveNotes"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ElementNotes',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
      notes: null
    }
  },
  created () {
    this.notes = this.element.notes
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async saveNotes () {
      this.showDialog = false
      try {
        await this.$axios.put(`/elements/${this.element._id}`, { notes: this.notes })
      } catch (error) {
        this.showSnackbar({ color: 'error', text: 'Saving notes failed' })
        throw error
      }
    }
  }
}
</script>
